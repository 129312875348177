import React, { useMemo, useState } from "react";

import { OptionCard } from "./OptionCard/OptionCard";

import { OptionPaymentAlert } from "./OptionPaymentAlert/OptionPaymentAlert";
import { useOption } from "../context/optionContext";
import { CardButton } from "../../../../../common/CardButton/CardButton";
import { useTranslation } from "next-i18next";

export const OptionsPaymentBlock = ({ options, visitOptions = [], title }) => {
  const [t] = useTranslation();

  const availableOptions = useMemo(() => {
    return options.map((option) => {
      const { id } = option;
      const ordered = visitOptions.find(({ id: optionId }) => optionId === id)?.ordered;
      return {
        ...option,
        ordered: !!ordered,
      };
    });
  }, [options, visitOptions]);

  const [showModal, setShowModal] = useState(false);
  const { removeOption } = useOption();

  return (
    <>
      {showModal && <OptionPaymentAlert title={title} availableOptions={availableOptions} onClose={() => setShowModal(false)} />}

      <div className="training-options">
        <div className="main-title-block training-options__title-block">
          <h3 className="subtitle training-options__title">{title}</h3>

          <CardButton
            title={t(visitOptions.length > 0 ? "change" : "add")}
            className={"primary-button training-options__button"}
            onClick={() => setShowModal(true)}
          />
        </div>

        {visitOptions.map(({ id, ...props }) => (
          <OptionCard {...props} key={id}>
            <div className="button-block">
              <CardButton title={t("remove")} className={"primary-button primary-button--reverse"} onClick={() => removeOption(id)} />
            </div>
          </OptionCard>
        ))}
      </div>
      <style jsx global>{`
        @import "./OptionsBlock.scss";
      `}</style>
    </>
  );
};
