import { useState, useCallback } from "react";

const BANNER_STORAGE_KEY = "bannerStatus";
const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;
const TEN_YEARS_MS = 2 * 365 * 24 * 60 * 60 * 1000;

interface UseBannerProps {
  iosUrl?: string;
  androidUrl?: string;
}

interface UseBannerReturn {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: (e: Event) => void;
}

/**
 * Custom hook to manage the banner visibility based on device type and storage status.
 * @param {UseBannerProps} param0 - Object containing the URLs for iOS and Android.
 * @returns {UseBannerReturn} - An object with the banner's open state and control functions.
 */
const useBanner = ({ iosUrl, androidUrl }: UseBannerProps): UseBannerReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    // Закрываем баннер, если нет соответствующей ссылки для устройства
    if ((isAndroid && !androidUrl) || (isIOS && !iosUrl)) {
      return false;
    }

    const bannerData = localStorage.getItem(BANNER_STORAGE_KEY);
    if (bannerData) {
      const { closed, closeDate } = JSON.parse(bannerData);
      const currentTime = Date.now();

      // Если баннер был закрыт и прошло менее недели, не показываем его
      if (closed && currentTime - closeDate < ONE_WEEK_MS) {
        return false;
      }
    }
    return true;
  });

  const updateBannerStatus = useCallback((closeDate: number) => {
    const bannerStatus = JSON.stringify({ closed: true, closeDate });
    localStorage.setItem(BANNER_STORAGE_KEY, bannerStatus);
    setIsOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    const closeDate = Date.now();
    updateBannerStatus(closeDate);
  }, [updateBannerStatus]);

  const handleOpen = useCallback(
    (e: Event): void => {
      const farFutureDate = Date.now() + TEN_YEARS_MS;
      updateBannerStatus(farFutureDate);
    },
    [updateBannerStatus]
  );

  return { isOpen, handleClose, handleOpen };
};

export { useBanner };
