import { useCallback, useEffect } from "react";
import { pageCountry } from "../graphql/reactivities";
import { usePageCountry } from "../utils/apollo/reactivities/useReactivities";
import { useRouter } from "next/router";

declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}

export const useCountryHook = (): {
  pageCountry: string | null;
  changeCountry: (country: string | null) => void;
  removeCountry: (x: void) => void;
} => {
  const router = useRouter();
  const {
    query: { country },
  } = router;

  const activeCountry = usePageCountry();

  const changeCountry = useCallback((country: string | null) => {
    pageCountry(country ?? "");
    localStorage.setItem("pageCountry", JSON.stringify(country));
  }, []);

  const removeCountry = useCallback(() => {
    localStorage.removeItem("pageCountry");
    pageCountry(undefined);
  }, []);

  useEffect(() => {
    if (activeCountry === country || !activeCountry) return;

    if (country) {
      changeCountry(country as string);
    }
  }, [activeCountry, country]);

  return { pageCountry: activeCountry, changeCountry, removeCountry };
};
