import React, { useState, useCallback, useRef } from "react";

import { PHONE_PLACEHOLDER } from "../SignUp/signUpConstants";
import { PHONE_UPDATE } from "../../../graphql/mutations";

import { useTranslation } from "next-i18next";
import SignUpAlertBlock from "../SignUp/SignUpAlertBlock";
import { PHONE_MIN_LENGTH } from '../../../constants';
import { useMutation } from "@apollo/client";

function ConfirmPhoneEnter({ phone, setPhone, setActiveWindow }) {
  const [t] = useTranslation();

  const phoneRef = useRef(null);

  const onChange = useCallback(({ target: { value } }) => {
    setPhone(value);
  }, []);

  const [{ phoneErrorMessage }, setData] = useState({
    phoneErrorMessage: "",
  });

  const inputsArray = [
    {
      isRequired: true,
      inputType: "tel",
      inputRef: phoneRef,
      onChange,
      value: phone,
      placeholderValue: PHONE_PLACEHOLDER,
      errorMessage: phoneErrorMessage,
    },
  ];

  const [phoneUpdate] = useMutation(PHONE_UPDATE);

  const onClickNextButton = useCallback(() => {
    setData({ phoneErrorMessage: "" });
    const {
      current: { value: phoneValue },
    } = phoneRef;
    let phoneErrorMessage = "";
    let isNumber = new RegExp("^[0-9]+$");

    if (!phoneValue.length) {
      phoneErrorMessage = t("required_field");
    } else if (phoneValue.length < PHONE_MIN_LENGTH) {
      phoneErrorMessage = t("length_error");
    } else if (!isNumber.test(phoneValue.slice(1))) {
      phoneErrorMessage = t("phone_symbols_error");
    }

    if (phoneErrorMessage) return setData({ phoneErrorMessage });

    phoneUpdate({ variables: { phone: phoneValue } }).then(
      ({ data: { phoneUpdate } }) => {
        if (phoneUpdate.ok) {
          setActiveWindow("submit");
          setPhone(phoneValue);
        }
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result === 5) return;
        setData({ phoneErrorMessage: message });
      }
    );
  }, []);
  return (
    <>
      <SignUpAlertBlock
        title={t("enter_phone_number")}
        nextButtonTitle={t("next")}
        inputsArray={inputsArray}
        onClickNextButton={onClickNextButton}
      />
    </>
  );
}

export default ConfirmPhoneEnter;
